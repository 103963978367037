<template>
  <div>
    <div class="flex flex-col space-y-6">
      <h2
        :class="titlePosition == 'left' ? 'text-start' : 'text-center'"
        class="text-2xl font-bold mt-2 mb-5"
      >
        {{ $t("common.howItWorks") }}
      </h2>
      <div class="flex w-full" v-if="filteredServices.length > 1">
        <Carousel
          id="thumbnails"
          :wrap-around="false"
          :v-model="currentSlide"
          :breakpoints="breakpoints"
          snapAlign="start"
          :itemsToShow="4"
          ref="myCarousel"
          class="w-full"
        >
          <Slide v-for="service in filteredServices" :key="service.id">
            <div
              :class="
                service.slug == currentService.slug
                  ? 'bg-secondary border-secondary text-white'
                  : 'border bg-transparent '
              "
              class="carousel__item px-4 py-2 mr-5 border rounded-full border-[#00A1AA99] font-semibold cursor-pointer cursor-pointer shrink-0"
              :id="service.id"
              @click="navigateTo(service)"
            >
              <div class="flex align-center">
                <NuxtImg
                  loading="lazy"
                  :src="service.icon?.permalink"
                  :class="
                    service.slug === currentService.slug
                      ? 'text-white'
                      : 'text-primary'
                  "
                  class="w-6 h-6 mr-3 group-hover:text-dark-blue"
                  alt=""
                />
                <span>{{ service.title }}</span>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
      <div
        class="w-full"
        :class="inModel == true ? 'md:w-5/6' : 'md:w-3/5'"
        v-if="filteredServices.length > 1"
      >
        <p class="text-sm md:text-base">{{ currentService.intro_text }}</p>
      </div>
      <div class="relative overflow-x-hidden">
        <div
          class="carousel-fade"
          :style="{
            '--scroll': scrollValue,
          }"
        ></div>

        <div
          class="relative w-full flex items-start gap-10 overflow-x-auto hideScrollBar snap-x snap-mandatory md:snap-none"
          id="how-it-works__steps"
          @scroll="handleScrollChange"
        >
          <div
            v-for="(step, index) in currentService?.steps ?? []"
            :key="step.id"
            class="snap-center"
          >
            <div
              class="rounded-sm mb-1"
              :class="
                inModel == true ? 'w-60 h-48' : 'md:w-72 md:h-60 w-60 h-48'
              "
            >
              <NuxtImg
                loading="lazy"
                :src="step.image?.permalink"
                :alt="step.title"
                class="object-cover object-center h-full w-full"
              />
            </div>
            <div class="flex flex-col space-y-3">
              <span
                class="font-bold text-primary text-sm uppercase tracking-[0.98px]"
              >
                {{ $t("common.howItWorksStep", { step: index + 1 }) }}
              </span>
              <span class="font-semibold">{{ step.title }}</span>
              <p class="text-sm">{{ step.intro_text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-16 mb-10">
      <CommonRangeSlider
        name="carousselle-range"
        id="how-it-works-range"
        :min="0"
        :max="100"
        :value="scrollValue"
        @input="handleRangeChange"
      />
    </div>

    <div
      class="flex justify-center mt-16 lg:mt-20 xl:mt-24 mb-0 md:mb-4"
      v-if="displayFindClinicButton == true"
    >
      <NuxtLinkLocale :locale="switchBlogLocale()" to="/clinics" class="flex w-full md:w-auto">
        <BaseButton
          class="md:w-80 w-full h-16 mx-0 text-lg"
          :text="$t('common.btnFindClinic')"
        ></BaseButton>
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<script setup>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

const props = defineProps({
  services: {
    type: Object,
    default: null,
  },
  inModel: {
    type: Boolean,
    default: false,
  },
  displayFindClinicButton: {
    type: Boolean,
    default: false,
  },
  titlePosition: {
    type: String,
    default: "center",
  },
  defaultService: {
    type: Object,
    required: false,
  },
});

const scrollValue = ref(0);
const availableServices = ref({});

const { data: serviceList } = await useHowItWorkServices();
availableServices.value = serviceList.value.data;

const filteredServices = computed(() =>
  availableServices.value.filter(
    (service) =>
      !props.services ||
      (props.services.length > 0 && props.services.includes(service.slug))
  )
);

const currentService = ref(
  filteredServices.value.find((x) => x.slug === props.defaultService?.slug) ??
    filteredServices.value[0]
);

const currentSlide = shallowRef(0);
const breakpoints = {
  // 700px and up
  700: {
    itemsToShow: 4,
    snapAlign: "start",
  },
  // 1024 and up
  1024: {
    itemsToShow: 5,
    snapAlign: "start",
  },
};

const handleRangeChange = (value) => {
  const scrollPercentage = parseInt(value ?? scrollValue.value) / 100;
  const stepsContainer = document.getElementById("how-it-works__steps");
  const scrollX =
    (stepsContainer.scrollWidth - stepsContainer.clientWidth) *
    scrollPercentage;
  stepsContainer.scrollLeft = scrollX;
};

const handleScrollChange = (e) => {
  /**
   * Carousel container.
   * @type {HTMLDivElement}
   */
  const container = e.target;

  const total = container.scrollWidth - container.offsetWidth;
  const percentage = container.scrollLeft / total;

  scrollValue.value = percentage * 100;
};

const navigateTo = (item) => {
  currentService.value = item;
  scrollValue.value = 0;
};
</script>

<style scoped lang="scss">
input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 3px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-moz-range-track {
  width: 300px;
  height: 3px;
  background: #dddddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #931363;
  cursor: pointer;
  margin-top: -6px;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #931363;
  cursor: pointer;
  margin-top: -6px;
}

input[type="range"]:focus {
  outline: none;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

ul {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  transform: translate3d(0, 0, 0);
}

ul::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.carousel__slide {
  width: auto !important;
}

.carousel__item:hover {
  -webkit-transition: background-color 300ms ease;
  -ms-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
}

.carousel__wrapper {
  width: calc(100% + 16px);
}

.carousel-fade {
  &::before,
  &::after {
    content: "";
    @apply w-1/2 h-full absolute top-0 from-[#f7f7f7] pointer-events-none z-[2];
    transform: translateX(calc(clamp(1, var(--scroll, 0), 99) * 1%));
  }

  &::before {
    @apply bg-gradient-to-r left-0;
    translate: -100%;
  }

  &::after {
    @apply bg-gradient-to-l right-0;
  }
}
</style>

<style>
.carousel__prev,
.carousel__next {
  display: none;
}
</style>
