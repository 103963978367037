<template>
  <div class="range-slider" :style="{
    '--value': props.value,
    '--trackHeight': props.trackHeight,
    '--thumbSize': props.thumbSize,
  }">
    <input v-bind="props" @input="handleInput" type="range" />
    <span class="range-slider__thumb"></span>
    <span class="range-slider__track"></span>
    <span class="range-slider__progress" v-show="!props.hideProgress"></span>
</div>
</template>

<script lang="ts" setup>
import type { Numberish } from '~/types/general';

interface RangeSliderEmits {
  (action: "input", value: number): void;
}

const emits = defineEmits<RangeSliderEmits>();

interface RangeSliderProps {
  name?: string;
  value?: Numberish;
  min?: Numberish;
  max?: Numberish;
  id?: string;
  hideProgress?: boolean;
  trackHeight?: string;
  thumbSize?: string;
}

const props = withDefaults(defineProps<RangeSliderProps>(), {
  min: 0,
  max: 100,
  value: 0,
  name: "range-slider",
  id: "range-slider",
  hideProgress: false,
});

function handleInput(event: Event) {
  const { valueAsNumber } = event.target as HTMLInputElement;
  emits("input", valueAsNumber);
}
</script>

<style scoped lang="scss">
.range-slider {
    --_percentage: calc(var(--value, 0) * 1%);
    --trackHeight: .2rem;
    --thumbSize: 1rem;

  @apply relative w-40 h-4 isolate;

  & > input {
    @apply absolute opacity-0;
    inset-inline: calc(-1 * calc(var(--thumbSize) / 2));
    inset-block: 0;
  }

  & > span {
    @apply absolute z-1 pointer-events-none left-0 top-1/2 -translate-y-1/2;

    &.range-slider__track {
        @apply bg-[#dddddd] w-full rounded-md z-[1];
        height: var(--trackHeight);
    }

    &.range-slider__progress {
        @apply bg-secondary left-0 z-[2];
        height: var(--trackHeight);
        width: var(--_percentage);
    }

    &.range-slider__thumb {
        @apply bg-secondary rounded-full aspect-square z-[3] -translate-x-1/2 transition-transform;
        width: var(--thumbSize);
        left: var(--_percentage);
    }
  }

  &:focus-within {

    & > span {

        &.range-slider__thumb {
            @apply scale-150;
        }

    }
  }
}
</style>
